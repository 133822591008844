
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://m-marineblue.com"
export const apiRoot = "https://m-marineblue.com/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"
export const hvnUriRoot = "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue"

// ヘブンブログパーツ類
export const hvnShopId = "2510019691";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "マリン水戸店",
    tel:        "029-306-7882",
    openTime:   "6:00~24:00",
    zipCode:    "310-0025",
    zipAddress: "茨城県 水戸市 天王町6-20",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.281557679869!2d140.45784531498035!3d36.37817748003762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60222445c78f0c8d%3A0xcafba2be7c79ec73!2z44CSMzEwLTAwMjUg6Iyo5Z-O55yM5rC05oi45biC5aSp546L55S677yW4oiS77yS77yQ!5e0!3m2!1sja!2sjp!4v1651196325624!5m2!1sja!2sjp",
};

export const priceChart = "/img/20240427_price.jpg"
export const priceChart0517 = "/img/20240806_price.jpg"

// 20240517 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0517

    }
    return priceChart
}

export const areaName = "茨城"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-system.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-access.png"},
//        {name: "内装紹介", path: "/interior", blank: true, img: "/img/ico/i-interior.png"},
        {name: "動画一覧", path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-movie.png"},
        {name: "写メ日記", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
        {name: "口コミ", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
        {name: "ネット予約", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
        {name: "男子求人", path: "https://mens-qzin.jp/ibaraki/area_08001/detail/marinebluemito/?v=official", blank: true, img: "/img/ico/i-rec-men.png"},
        {name: "女子求人", path: "https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/mt-marin-blue/?of=y", blank: true, img: "/img/ico/i-rec-women.png"},
];


export const slideArr = [
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "日本一！極大ソープランド マリン水戸店",
            pc: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue",
            sp: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue&sp=t",
        },
    },
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "",
            pc: "/img/slides/20240715/s02-pc.jpg",
            sp: "/img/slides/20240715/s02-sp.jpg",
        },
    },
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "フリー限定イベント 40分10,000円",
            pc: "/img/slides/20240715/s03-pc.jpg",
            sp: "/img/slides/20240715/s03-sp.jpg",
        },
    },
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "プレミアムキャスト降臨 ご予約必須",
            pc: "/img/slides/20240826/s04-pc.jpg",
            sp: "/img/slides/20240826/s04-sp.jpg",
        },
    },
    /*
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "イベント 新人割引 60分21,000円 9:00~13:59限定 14:00~16:59 +1,000円, 19:00~23:00 +2000円", 
            imgPathSp: "/img/slides/20231204_s02.jpg", },
    },   
    */
    /*
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "", 
        imgPathSp: "/", 
        imgPathPc: "", },
    },
    */
];