import { useEffect, useState } from "react";
import axios from "axios";
import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import {h2Unlinked, h2Linked, castCard, diaryArticle} from"./core/core";
import { apiRoot, hvnApi, shopInfo, areaName, diaryApiRoot, hvnUriRoot, slideArr} from "./core/defines";

import PcNav from "./components/pcNav";
import SideNav from "./components/sideNav";


// SPスライドのみ 出口3つあってキモい
function slideMaker(obj){
    if(obj.link.uri !== null){
        if(obj.link.blank !== false ){
            return(
                <SwiperSlide>
                    <a href={obj.link.uri} target="_blank" rel="noreferrer noopner">
                        <img src={ obj.img.imgPathSp } alt={ obj.img.alt } />
                        <picture>
                            <source media="(max-width: 720px)" srcSet={obj.img.sp} />
                            <source media="(min-width: 721px)" srcSet={obj.img.pc} />
                            <img src={obj.img.pc} alt={obj.img.alt} />
                        </picture>
                    </a>
                </SwiperSlide> 
            ) 
        }
            
        else{
            return(
                <SwiperSlide>
                    <a href={obj.link.uri}>
                        <picture>
                            <source media="(max-width: 720px)" srcSet={obj.img.sp} />
                            <source media="(min-width: 721px)" srcSet={obj.img.pc} />
                            <img src={obj.img.pc} alt={obj.img.alt} />
                        </picture>
                    </a>
                </SwiperSlide> 
            ) 
        }
    }

    else{
        return(
            <SwiperSlide>
                <picture>
                    <source media="(max-width: 720px)" srcSet={obj.img.sp} />
                    <source media="(min-width: 721px)" srcSet={obj.img.pc} />
                    <img src={obj.img.pc} alt={obj.img.alt} />
                </picture>
            </SwiperSlide> 
        ) 
    }
}

function limitedSlide(slideData, 
                      endDateFromat, 
                      startDateFromat=null){

    // console.log(slideData)
    
    const now = new Date()
    const end = new Date(endDateFromat)

    // 表示開始日時の指定あり
    if(startDateFromat !== null) {
        const start = new Date(startDateFromat)

        if(start.getTime() < now.getTime() &&
           now.getTime() < end.getTime()){
            
            return slideMaker(slideData)
        }
    }

    // 開始の指定なし (期日まで常設)
    else if(now.getTime() < end.getTime()){

        return slideMaker(slideData)
    }
}


function Home(){
    /*
    const syogatsuSlide = {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "当店は年末年始も休まず営業いたします", 
            imgPathSp: "/img/slides/slide_syogatsu.jpg", },
        }
    */
    
    const [casts,   setSchedule]  = useState([]);
    const [newface, setNewface]   = useState([]);

    useEffect(()=>{
        const fetchSchedule = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/schedule.php`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // setSchedule(data=res.data);
                // console.log(res.data);
                setSchedule(res.data);
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        const fetchNewface = () =>{
            axios({
            method: "get",
            url: `${apiRoot}/newface.php?m=top`
            })
            .then((res) => {
                console.log("success");
                //console.log(res.data);
                // console.log(res.data);
                setNewface(res.data);
                // console.log(res.data)
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        /*
        const fetchDiary = () =>{
            axios({
            method: "get",
            url: `${diaryApiRoot}/diary?t=${hvnUriRoot}/diarylist`
            })
            .then((res) => {
                //console.log(res.data);
                // console.log(res.data);
                setHvnDiary(res.data);
                // console.log(res.data)
            })
            .catch(err =>{
                console.log("err", err);
            });
        }
        */

        fetchSchedule();
        fetchNewface();
        // fetchDiary();
},[]);

const topBanner = slideArr[0].img
// console.log(topBanner)
// console.log(slideArr)

    return(
        <>
            <header>
                <div id="sp-header" className="sp txt-center">
                    <p className="title">{areaName}のソープランド【{ shopInfo.name }】</p>
                    <div className="info">
                        <address>TEL { shopInfo.tel }</address>
                        <p>営業時間 { shopInfo.openTime }<br />ご予約随時受付中</p>
                    </div>
                </div>
                <p className="pc"><img src={topBanner.pc} /></p>
                <PcNav />
            </header>
            <div className="container bg-w-alpha">
                <section id="home" className="main-colmn">
                    <section>
                        <Swiper
                            modules={[Autoplay]}
                            loop={true}
                            autoplay={true}
                            >
                                {/* limitedSlide({...syogatsuSlide}, "2023/1/4") */}
                                {slideArr.map((s)=>slideMaker(s))}
                        </Swiper>
                    </section>
                    <article class="schedule block">
                        {h2Linked("本日の出勤", "出勤一覧", "/schedule")}
                        <ul class="wrapper-sc">
                            {casts.map((c) => 
                                <li className="card-cast" key={c.c_id}>
                                    {castCard(c)}
                                </li>
                            )}
                        </ul>
                    </article>
                        <div className="pc">
                            <article id="home-hvn-diary" className="block">
                                {h2Unlinked("写メ日記")}
                                <iframe className="wrappar-hvn-video block" src="https://blogparts.cityheaven.net/widget/?shopId=2510019691&amp;mode=2&amp;type=14&amp;num=5&amp;col=3&amp;color=2&amp;fontcolor=10&amp;btncolor=6&amp;fontsize=15"></iframe>
                                <a className="btn" href={ hvnUriRoot+ "/diarylist"}
                                    target="_blank" rel="noreferrer noopner">一覧はこちら!</a>
                                
                            </article>
                            <article id="hvn-videos" className="wrappar-hvn-video block">
                                {h2Unlinked("MOVIE")}
                                <iframe src={hvnApi.Video} />
                            </article>
                        </div>
                    <article className="newface block">
                        {h2Linked("新人", "新人一覧", "/newface")}
                        <Swiper
                            modules={[Autoplay]}
                            autoplay={true}
                            loop={true}
                            
                            slidesPerView={1.2}
                            centeredSlides={true}
                            spaceBetween={5}

                            breakpoints={{ 768:{slidesPerView:3},}}
                            >
                            {newface.map((el)=>
                                <SwiperSlide
                                    className="card-cast txt-center"
                                    key={el.C_id}>
                                        {castCard(el)}
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </article>
                    <article className="pc">
                        {h2Unlinked("ACCESS")}
                        <iframe className="iframe-map" src={shopInfo.gmapUri}></iframe>
                    </article>
                </section>
                <SideNav />
            </div>
        </>
    );
}

export default Home;